import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Stack, Chip, Box, Typography } from '@mui/material'

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled'
import logo from 'assets/img/logo2.png'
import { Link } from 'react-router-dom'
import { LOCALE } from 'properties/Locale'

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme()

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Link to={'/home'}>
        <Box
          component="img"
          sx={{
            height: 180,
          }}
          src={logo}
        />
      </Link>
    </DrawerHeaderStyled>
  )
}

DrawerHeader.propTypes = {
  open: PropTypes.bool,
}

export default DrawerHeader
