import React from 'react'
import { createRoot } from 'react-dom/client'
import { Route, BrowserRouter, Navigate } from 'react-router-dom'
import { configureStore } from '@reduxjs/toolkit'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createBrowserHistory } from 'history'
import httpService from './app/api/HttpClient'
import { Provider as ReduxProvider } from 'react-redux'
import { StrictMode } from 'react'
import { store } from 'store'

const container = document.getElementById('root')
const root = createRoot(container)

httpService.setupInterceptors(store)
const history = createBrowserHistory({ forceRefresh: true })

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
