// material-ui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

// ==============================|| DRAWER HEADER - STYLED ||============================== //

const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // justifyContent: open ? 'flex-start' : 'center',
  backgroundColor: "#001833",
  height: 300,
}));

export default DrawerHeaderStyled;
