// project import
import pages from './pages'
import dashboard from './dashboard'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [pages],
}

export default menuItems
