import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
// const AuthRegister = Loadable(
//   lazy(() => import("pages/authentication/Register"))
// );

//Email confirmation
const EmailConfirmation = Loadable(
  lazy(() => import("pages/email-confirmation/EmailConfirmation"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/app",
  element: <MinimalLayout />,
  children: [
    {
      path: "/app/login",
      element: <AuthLogin />,
    },
    {
      path: "/app/email-confirmation",
      element: <EmailConfirmation />,
    },
  ],
};

export default LoginRoutes;
