import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import PublicLayout from "layout/PublicLayout";
import { AuthService } from "auth/AuthService";
import { Navigate } from "react-router-dom";

//Registration
const OffersAndPackages = Loadable(
  lazy(() => import("pages/subscribe/OffersAndPackages"))
);
const EnterInformation = Loadable(
  lazy(() => import("pages/subscribe/EnterInformation"))
);
const RegistrationSuccessCard = Loadable(
  lazy(() => import("pages/subscribe/RegistrationSuccessCard"))
);
const RegistrationUnsuccessfulCard = Loadable(
  lazy(() => import("pages/subscribe/RegistrationUnsuccessfulCard"))
);

// render - Public
const CookiesPolicy = Loadable(
  lazy(() => import("components/CookiesComponent/CookiesPolicy"))
);
const TermsOfUseComponent = Loadable(
  lazy(() => import("components/TermsOfUse/TermsOfUseComponent"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("components/PrivacyPolicy/PrivacyPolicyComponent"))
);
const HomepagePublicComponent = Loadable(
  lazy(() => import("pages/homepage/HomepagePublicComponent"))
);
const HomepageModalCharts = Loadable(
  lazy(() => import("components/DataAdministrationGraph/HomepageModalCharts"))
);
const AboutSmeseComponent = Loadable(
  lazy(() => import("pages/about-smese/AboutSmeseComponent"))
);
const B2bCooperationComponent = Loadable(
  lazy(() => import("pages/b2b-cooperation/B2bCooperationComponent"))
);
const PartnerNetworkComponent = Loadable(
  lazy(() => import("pages/b2b-cooperation/PartnerNetworkComponent"))
);
const CompanyDetailsComponent = Loadable(
  lazy(() => import("pages/b2b-cooperation/CompanyDetailsComponent"))
);
const ContactCompanyModalComponent = Loadable(
  lazy(() => import("pages/b2b-cooperation/ContactCompanyModalComponent"))
);
const PromoApproachComponent = Loadable(
  lazy(() => import("pages/promo-approach/PromoApproachComponent"))
);
const ActiveMarketComponent = Loadable(
  lazy(() => import("pages/active-market/ActiveMarketComponent"))
);
const MarketValuesComponent = Loadable(
  lazy(() => import("pages/market-values/MarketValuesComponent"))
);
const SebAcademyComponent = Loadable(
  lazy(() => import("pages/seb-academy/SebAcademyComponent"))
);
const ContactUsComponent = Loadable(
  lazy(() => import("pages/contact-us/ContactUsComponent"))
);

//payment
const PaymentStatus = Loadable(
  lazy(() => import("pages/paymentStatus/PaymentStatus"))
);

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
  path: "/",
  element: <PublicLayout />,
  children: [
    //registration components
    {
      path: "/register",
      element: <EnterInformation />,
    },
    {
      path: "/register/packages",
      element: <OffersAndPackages />,
    },
    {
      path: "/register/successfully-created",
      element: <RegistrationSuccessCard />,
    },
    {
      path: "/register/unsuccessful-registration",
      element: <RegistrationUnsuccessfulCard />,
    },
    //publics
    {
      path: "/cookies-policy",
      element: <CookiesPolicy />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsOfUseComponent />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/payment-status",
      element: <PaymentStatus />,
    },
    {
      path: "/home",
      element: <HomepagePublicComponent />,
      children: [
        {
          path: "/home/chart-modal",
          element: <HomepageModalCharts />,
        },
      ],
    },

    {
      path: "/about-smese",
      element: <AboutSmeseComponent />,
    },
    {
      path: "/b2b",
      element: <B2bCooperationComponent />,
      children: [
        {
          path: "/b2b/partners/:partnerCode",
          element: <PartnerNetworkComponent />,
        },
      ],
    },
    {
      path: "/b2b/company-details/:companyId",
      element: (
        <RequireAuth redirectTo="/b2b">
          <CompanyDetailsComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/b2b/company-details/:companyId/contact-us",
          element: (
            <RequireAuth redirectTo="/b2b">
              <ContactCompanyModalComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/promo-approach",
      element: <PromoApproachComponent />,
    },
    {
      path: "/active-market",
      element: <ActiveMarketComponent />,
    },
    {
      path: "/market-values",
      element: <MarketValuesComponent />,
    },
    {
      path: "/seb-academy",
      element: <SebAcademyComponent />,
    },
    {
      path: "/contact-us",
      element: <ContactUsComponent />,
    },
  ],
};

function RequireAuth({ children, redirectTo }) {
  const data = AuthService.getCurrentUser();
  var isAuth = window.localStorage.getItem("isAuth");
  // const { isAuthenticated, user, loading } = useSelector((state) => state.auth)

  // const userHasRequiredRole = user && roles.includes(user.role) ? true : false
  if (!isAuth) {
    return <Navigate to={redirectTo} replace />;
  }

  // if (isAuthenticated && !userHasRequiredRole) {
  //   return <AccessDenied /> // build your won access denied page (sth like 404)
  // }

  return children;
}

export default PublicRoutes;
