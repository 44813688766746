// third-party
import { combineReducers } from 'redux'

// project import
import menu from './menu'
import app from './app'

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, app })

export default reducers
