import CookieConsent from "react-cookie-consent";
import CookiesAcceptButton from "./CookiesAcceptButton";
import { LOCALE } from "properties/Locale";
import { Link, useLocation } from "react-router-dom";

const CookiesComponent = () => {
  const location = useLocation();

  return (
    <CookieConsent
      location="bottom"
      cookieName="sebglobal_cookies"
      //   ButtonComponent={CookiesAcceptButton}
      buttonText={LOCALE.accept_cookies}
      disableStyles
      expires={150}
      buttonStyle={{
        backgroundColor: "#E05745",
        color: "#F3F3F3",
        fontSize: "13px",
        cursor: "pointer",
        padding: 6,
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#2B373B",
        flex: "0 0 100%",
        padding: "15px",
        zIndex: location.pathname === "/cookies-policy" ? 0 : 999999999,
        backgroundColor: "rgba(1, 1, 1, 0.9)",
        borderTop: "2px solid #e05745",
        textAlign: "center",
        position: "fixed",
        bottom: "0px",
        left: "0px",
        color: "white",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: "18px", marginBottom: 6 }}>
          {LOCALE.cookies_consent}
        </span>
        <span style={{ fontSize: "16px", width: "80%", marginBottom: 9 }}>
          {LOCALE.general_info}{" "}
          <span>
            <Link to={"/cookies-policy"} style={{ color: "white" }}>
              {LOCALE.more_info_here}.{" "}
            </Link>
            {/* {LOCALE.cookies_policy}. */}
          </span>
        </span>
      </div>
    </CookieConsent>
  );
};

export default CookiesComponent;
