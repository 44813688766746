import Axios from "axios";

export const CompanyFinancialDataRepository = {
  fetchAllFinancialDataForCompany: (id) => {
    return Axios.get(`/api/CompanyFinancialData/GetAllFinancialDataForCompany/${id}`);
  },

  fetchDataForTelop: (id) => {
    return Axios.get(`/api/CompanyFinancialData/GetFinancialDataBySectorForTelop/${id}`);
  },

  fetchAllCompanyFinancialDataHistory: (payload) => {
    return Axios({
      url: `/api/CompanyFinancialData/GetCompanyFinancialDataBySearchParameters`,
      method: "GET",
      params: {
        // CompanyFinancialDataId: payload.companyFinancialDataId,
        // Month: payload.month,
        // Year: payload.year,
        CompanyId: payload.companyId,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchCompanyFinancialData: (payload) => {
    return Axios({
      url: `/api/CompanyFinancialData/GetCompanyFinancialDataCalculation`,
      method: "GET",
    });
  },

  fetchCompanyFinancialDataBySectorPreview: (activitySectorId, countryId) => {
    return Axios.get(
      `/api/CompanyFinancialData/GetFinancialDataBySectorForPreview/${activitySectorId}/${countryId}`
    );
  },

  fetchCompanyFinancialDataForPreview: (countryId) => {
    return Axios.get(`/api/CompanyFinancialData/GetFinancialDataForPreview/${countryId}`);
  },

  fetchCompanyFinancialDataForMarketValues: (countryId, activitySectorId) => {
    return Axios.get(
      `/api/CompanyFinancialData/GetFinancialDataForMarketValues/${countryId}/${activitySectorId}`
    );
  },

  // fetchCompanyFinancialDataForMarketValues: (payload) => {
  //   return Axios({
  //     url: `/api/CompanyFinancialData/GetCompanyFinancialDataCalculation/${0}/${0}`,
  //     method: "GET",
  //     //   params: {
  //     //     year: payload.year,
  //     //     month: payload.month,
  //     //     companyId: payload.companyId,
  //     //   },
  //   });
  // },

  fetchCompanyFinancialDataById: (id) => {
    return Axios.get(`/api/CompanyFinancialData/GetCompanyFinancialDataById/${id}`);
  },

  deleteCompanyFinancialData: (companyId) => {
    return Axios.delete(`/api/CompanyFinancialData/DeleteCompanyFinancialData/${companyId}`);
  },
};
