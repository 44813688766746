import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { AuthService } from "auth/AuthService";
import { LOCALE } from "../properties/Locale";

const pages = {
  id: "group-administration",
  type: "group",
  children: [
    {
      id: "profile",
      title: LOCALE.profile,
      type: "item",
      url: "/app/profile",
      // icon: DashboardIcon,
      hasAccess: ["RoleUser"],
    },
    {
      id: "user-administration",
      title: LOCALE.menu_user_administration,
      type: "item",
      url: "/app/user-administration",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "data-administration",
      title: LOCALE.menu_data_administration,
      type: "item",
      url: "/app/data-administration",
      // icon: DashboardIcon,
      hasAccess: ["RoleUser"],
    },
    {
      id: "sectors",
      title: LOCALE.menu_sectors,
      type: "item",
      url: "/app/sectors",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "telop",
      title: LOCALE.menu_telop,
      type: "item",
      url: "/app/telop",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "publications",
      title: LOCALE.menu_publications,
      type: "item",
      url: "/app/publications",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "activity-types",
      title: LOCALE.activity_types,
      type: "item",
      url: "/app/activity-types",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "subscription-packages",
      title: LOCALE.subscriptionPackages,
      type: "item",
      url: "/app/subscription-packages",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "banner-types",
      title: LOCALE.bannerTypes,
      type: "item",
      url: "/app/banner-types",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "banner-images",
      title: LOCALE.bannerImages,
      type: "item",
      url: "/app/banner-images",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "events",
      title: LOCALE.events,
      type: "item",
      url: "/app/events",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "subscription-pricelist",
      title: LOCALE.menu_subscription_pricelist,
      type: "item",
      url: "/app/subscription-pricelist",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "admin-report",
      title: LOCALE.report,
      type: "item",
      url: "/app/admin-report",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },
    {
      id: "user-report",
      title: LOCALE.report,
      type: "item",
      url: "/app/user-report",
      // icon: DashboardIcon,
      hasAccess: ["RoleUser"],
    },
    {
      id: "calculation",
      title: LOCALE.calculation,
      type: "item",
      url: "/app/calculation",
      // icon: DashboardIcon,
      hasAccess: ["RoleAdmin"],
    },

    // {
    //   id: "test",
    //   title: LOCALE.menu_test
    //   type: "collapse",
    //   url: "",
    //   icon: SettingsIcon,
    //   hasAccess: ["SuperAdmin"],
    //   children: [
    //     {
    //       id: "testchild",
    //       title: LOCALE.menu_test_child,
    //       type: "children",
    //       url: "/test/testchild",
    //       hasAccess: ["SuperAdmin"],
    //     },
    //   ],
    // },
  ],
};

export default pages;
