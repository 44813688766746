import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Snackbar, Toolbar, useMediaQuery } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
import navigation from "menu-items";
import Breadcrumbs from "components/@extended/Breadcrumbs";

// types
import { openDrawer } from "store/reducers/menu";
import { clearNotifyMessage } from "store/reducers/app";

// auth
import { AuthService } from "auth/AuthService";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MainLayout() {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { drawerOpen } = useSelector((state) => state.menu);
  const { openNotification, message, variant } = useSelector((state) => state.app);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  // if first time login without changed password (user created automatically)
  // useEffect(() => {
  //   if (AuthService.getDecodedUserJWT().IsFirstLogin === "True") {
  //     navigate("/app/enter-new-password");
  //   }
  // }, [window.location.href]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const handleClose = () => {
    if (openNotification) {
      dispatch(clearNotifyMessage());
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
        }}
      >
        <Toolbar />
        <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
        <Outlet />
      </Box>
      {openNotification && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={variant}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
