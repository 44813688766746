import Axios from 'axios'
import { AuthService } from '../../auth/AuthService'
import { AuthRepository } from '../../auth/AuthRepository'
import { LOCALE } from '../../properties/Locale'
import {
  notifyLoaderApiCallFinish,
  notifyLoaderApiCallStart,
  notifyShowErrorMessage,
} from 'store/reducers/app'

export default {
  setupInterceptors: (store) => {
    // Add a request interceptor
    Axios.interceptors.request.use(
      function (config) {
        store.dispatch(notifyLoaderApiCallStart())
        // Do something before request is sent
        if (!config.url.includes('/metrics/')) {
          store.dispatch(notifyLoaderApiCallStart())
        }
        if (
          !config.url.includes('access_token=') &&
          !config.url.includes('/login') &&
          !config.url.includes('/register')
        ) {
          if (!config.params) {
            config['params'] = {}
          }
          config.headers['Authorization'] = `Bearer ${AuthService.getToken()}`
          config.headers['Accept-Language'] = 'mk-MK'
        }

        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      },
    )
    // Add a response interceptor
    Axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.dispatch(notifyLoaderApiCallFinish())
        return response
      },
      function (err) {
        if (err.message === 'Network Error') {
          store.dispatch(
            notifyShowErrorMessage({ message: LOCALE.networkError }),
          )
        }
        if (err.response?.data?.error?.includes('invalid_token')) {
          AuthRepository.logout()
        }
        if (err.response?.data?.error?.includes('access_denied')) {
          window.location.href = '/error/403'
        }
        if (err.response?.data?.message) {
          store.dispatch(
            notifyShowErrorMessage({ message: err.response?.data?.message }),
          )
        }
        if (err.response?.status === 403) {
          window.location.href = '/error/403'
        }

        store.dispatch(notifyLoaderApiCallFinish())
        return Promise.reject(err)
      },
    )
  },
}
