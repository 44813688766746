// ==============================|| OVERRIDES - BADGE ||============================== //

export default function AccordionSummary(theme) {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          transition: "none",
          "&.Mui-expanded": {
            transform: "none",
          },
        },
      },
    },
  };
}
