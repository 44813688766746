import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { LOCALE } from "properties/Locale";

//mui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import LoginIcon from "@mui/icons-material/Login";
import RegisterIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/MailOutline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

//logo
import logo from "../../assets/img/logo2.png";
import ChangeLanguageComponent from "components/ChangeLanguageComponent";

import Profile from "layout/MainLayout/Header/HeaderContent/Profile";
import MenuTelopComponent from "components/TelopComponents/MenuTelopComponent";

const drawerWidth = 240;
const navItems = [
  { id: 1, name: LOCALE.login, href: "/app/login", icon: <LoginIcon /> },
  {
    id: 2,
    name: LOCALE.register,
    href: "/register",
    icon: <RegisterIcon />,
  },
];

const loginMobileItems = [
  { id: 1, name: LOCALE.my_profile, href: "/app/profile" },
];

const menuItems = [
  { id: 1, name: LOCALE.homepage, href: "/home" },
  { id: 2, name: LOCALE.about_smese, href: "/about-smese" },
  { id: 3, name: LOCALE.b2b_cooperation, href: "/b2b" },
  { id: 4, name: LOCALE.promo_approach, href: "/promo-approach" },
  { id: 5, name: LOCALE.active_market, href: "/active-market" },
  { id: 6, name: LOCALE.market_values, href: "/market-values" },
  { id: 7, name: LOCALE.seb_academy, href: "/seb-academy" },
  { id: 8, name: LOCALE.contact, href: "/contact-us" },
];

function PublicLayout(props) {
  const { window } = props;
  const [currentMenuItem, setCurrentMenuItem] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  //auth
  const isAuthenticated = localStorage.getItem("isAuth");

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
      setCurrentMenuItem("/home");
    } else if (location.pathname === "/register") {
      setCurrentMenuItem("");
    } else if (location.pathname === "/home") {
      setCurrentMenuItem("/home");
    }
    setCurrentMenuItem(location.pathname);
  }, [location.pathname, navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Link to={"/home"} style={{ textDecoration: "none", lineHeight: 0 }}>
        <Box
          component="img"
          sx={{
            // height: 125,
            width: 100,
            maxHeight: { xs: 233 },
            maxWidth: { xs: 100 },
            paddingY: 1,
            marginLeft: 2,
          }}
          src={logo}
        />
      </Link>

      {/* <Typography variant="h6" marginLeft={2}>
        {LOCALE.smese}
      </Typography> */}

      <Divider />

      <List>
        {isAuthenticated
          ? loginMobileItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton>
                  <Link
                    to={item.href}
                    style={{ textDecoration: "none", color: "#001833" }}
                  >
                    <ListItemText primary={item.name} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))
          : navItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton>
                  <Link
                    to={item.href}
                    style={{ textDecoration: "none", color: "#001833" }}
                  >
                    <ListItemText primary={item.name} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
        {menuItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton>
              <Link
                to={item.href}
                style={{ textDecoration: "none", color: "#001833" }}
              >
                <ListItemText primary={item.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {/* Menu navbar */}
      <AppBar component="nav" sx={{ backgroundColor: "#fafafb", top: 52 }}>
        <Box sx={{ ml: 10, mr: 10 }}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            // spacing={9}
            alignItems="center"
            justifyContent="space-evenly"
            sx={{
              display: { xs: "none", sm: "flex" },
              pt: 4,
              pb: 1,
            }}
          >
            {menuItems.map((item, idx) => (
              <Link
                key={idx}
                to={item.href}
                onClick={() => {
                  setCurrentMenuItem(item.href);
                }}
                style={{
                  textDecoration: "none",
                  color: "#031d38f5",
                  textTransform: "uppercase",
                  fontSize: 14,
                  fontWeight: "600",
                  paddingBottom: 5,
                  borderBottom:
                    item.href === currentMenuItem
                      ? "2px solid #E05745"
                      : "2px solid transparent",
                }}
              >
                {item.name}
              </Link>
            ))}
          </Stack>
        </Box>
      </AppBar>
      {/* Small navbar */}
      <AppBar
        component="nav"
        sx={{ backgroundColor: "#001833", md: { paddingX: 10 } }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            display: "flex",
            paddingTop: "2px",
            paddingBottom: "1px",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#031d38" }}
          >
            <MenuIcon />
          </IconButton>
          <Link to={"/home"} style={{ textDecoration: "none", lineHeight: 0 }}>
            <Box
              component="img"
              sx={{
                // height: 125,
                width: 100,
                maxHeight: { xs: 233, md: 157 },
                maxWidth: { xs: 350, md: 240 },
              }}
              src={logo}
            />
          </Link>
          <Box sx={{ width: "70%" }}>
            <MenuTelopComponent />
          </Box>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {isAuthenticated ? (
              <Profile />
            ) : (
              <Stack
                gap={2}
                alignItems={"center"}
                direction={"row"}
                justifyContent="space-between"
                className="the-stack"
              >
                {navItems.map((item) => (
                  <Typography key={item.id} sx={{ color: "white" }}>
                    <Link
                      to={item.href}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Stack
                        gap={1}
                        alignItems={"center"}
                        direction={"row"}
                        justifyContent="space-between"
                      >
                        {item.icon}
                        {item.name}
                      </Stack>
                    </Link>
                  </Typography>
                ))}
                <ChangeLanguageComponent />
              </Stack>
            )}
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <ChangeLanguageComponent />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          // marginTop: 5, //ako nema logo ? true
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

PublicLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PublicLayout;
