// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  loading: false,
  openNotification: false,
  success: false,
  error: false,
  triggerRerender: false,
  variant: "",
  message: "",
  countryDataId: 3,
};

// ==============================|| SLICE - MENU ||============================== //

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    rerender(state, action) {
      state.triggerRerender = !state.triggerRerender;
    },
    countryData(state, action) {
      state.countryDataId = action.payload.countryDataId;
    },
    notifyShowSuccessMessage(state, action) {
      state.openNotification = true;
      state.variant = "success";
      state.message = action.payload.message;
    },
    notifyShowErrorMessage(state, action) {
      state.openNotification = true;
      state.variant = "error";
      state.message = action.payload.message;
    },
    notifyShowWarningMessage(state, action) {
      state.openNotification = true;
      state.variant = "warning";
      state.message = action.payload.message;
    },
    notifyShowInfoMessage(state, action) {
      state.openNotification = true;
      state.variant = "info";
      state.message = action.payload.message;
    },
    clearNotifyMessage(state, action) {
      state.openNotification = false;
    },
    notifyLoaderApiCallStart(state) {
      state.loading = true;
    },
    notifyLoaderApiCallFinish(state) {
      state.loading = false;
    },
  },
});

export default app.reducer;

export const {
  rerender,
  countryData,
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  notifyShowWarningMessage,
  notifyShowInfoMessage,
  clearNotifyMessage,
  notifyLoaderApiCallStart,
  notifyLoaderApiCallFinish,
} = app.actions;
