//react
import React, { useEffect, useState } from "react";

//redux
import { useSelector } from "react-redux";

//repo
import { CompanyFinancialDataRepository } from "repositories/CompanyFinancialData";

//material-ui
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import ArrowDown from "@mui/icons-material/ArrowDownward";

export default function MenuTelopComponent() {
  const [telopData, setTelopData] = useState();

  const { countryDataId } = useSelector((state) => state.app);

  //selected locale
  const localeCountry = localStorage.getItem("locale");

  useEffect(() => {
    loadTelopData(countryDataId);
  }, [localeCountry, countryDataId]);

  const loadTelopData = (countryId) => {
    CompanyFinancialDataRepository.fetchDataForTelop(countryId)
      .then((res) => {
        setTelopData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="slider-menu">
        <div className="slide-track">
          {telopData?.map((item) => {
            return (
              <div className="slide">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: 8,
                    borderLeft: "1px solid #e05745",
                    borderRight: "1px solid #e05745",
                    paddingLeft: 2,
                    backgroundColor: "#F3F3F3",
                  }}
                >
                  <Box sx={{ marginRight: 2, width: 250, textAlign: "center" }}>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "12px",
                        lineHeight: 0.9,
                      }}
                    >
                      {localeCountry === "en"
                        ? item?.descriptionEN
                        : localeCountry === "mk"
                        ? item?.descriptionMK
                        : localeCountry === "rs"
                        ? item?.descriptionRS
                        : localeCountry === "al"
                        ? item?.descriptionAL
                        : item?.descriptionBG}
                    </Typography>
                  </Box>
                  <Box>
                    <TableContainer
                      component={Paper}
                      sx={{ backgroundColor: "#F3F3F3" }}
                    >
                      <Table sx={{ minWidth: 800 }} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontSize: "11px",
                                padding: 0,
                                textAlign: "center",
                              }}
                            >
                              NPM
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "11px",
                                padding: 0,
                                textAlign: "center",
                              }}
                            >
                              ROE
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "11px",
                                padding: 0,
                                textAlign: "center",
                              }}
                            >
                              SFC
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "11px",
                                padding: 0,
                                textAlign: "center",
                              }}
                            >
                              ATR
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "11px",
                                padding: 0,
                                textAlign: "center",
                              }}
                            >
                              ROA
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "11px",
                                padding: 0,
                                textAlign: "center",
                              }}
                            >
                              HCI
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ padding: 0 }}>
                              <Stack
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                justifyContent="center"
                                fontSize="11px"
                              >
                                <ArrowDown
                                  sx={{ color: "red", marginRight: 1 }}
                                />{" "}
                                {item?.npm.toFixed(5)}
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ padding: 0 }}>
                              <Stack
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                justifyContent="center"
                                fontSize="11px"
                              >
                                <ArrowUp
                                  sx={{ color: "green", marginRight: 1 }}
                                />{" "}
                                {item?.roe.toFixed(5)}
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ padding: 0 }}>
                              <Stack
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                justifyContent="center"
                                fontSize="11px"
                              >
                                <ArrowUp
                                  sx={{ color: "green", marginRight: 1 }}
                                />{" "}
                                {item?.sfc.toFixed(5)}
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ padding: 0 }}>
                              <Stack
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                justifyContent="center"
                                fontSize="11px"
                              >
                                <ArrowDown
                                  sx={{ color: "red", marginRight: 1 }}
                                />{" "}
                                {item?.atr.toFixed(5)}
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ padding: 0 }}>
                              <Stack
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                justifyContent="center"
                                fontSize="11px"
                              >
                                <ArrowUp
                                  sx={{ color: "green", marginRight: 1 }}
                                />{" "}
                                {item?.roa.toFixed(5)}
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ padding: 0 }}>
                              <Stack
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                justifyContent="center"
                                fontSize="11px"
                              >
                                <ArrowDown
                                  sx={{ color: "red", marginRight: 1 }}
                                />{" "}
                                {item?.hci.toFixed(5)}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
