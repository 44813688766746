import Axios from "axios";

export const CompanyRepository = {
  fetchAllCompaniesBySearchParameters: (payload) => {
    return Axios({
      url: `/api/Companies/GetCompaniesBySearchParameters`,
      method: "GET",
      params: {
        CompanyId: payload.companyId,
        Name: payload.name,
        UniqueId: payload.uniqueId,
        CountryId: payload.countryId,
        CompanySizeId: payload.companySizeId,
        ActivityTypeId: payload.activityTypeId,
        Recommendation: payload.recommendation,
        IsSociallyResponsible: payload.isSociallyResponsible,
        IsPartner: payload.isPartner,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchAllCompanies: () => {
    return Axios.get("/api/Companies/GetAllCompanies");
  },

  fetchAllCompaniesByPartnerCode: (partnerCode) => {
    return Axios.get(
      `/api/Companies/GetAllCompaniesByPartnerCode/${partnerCode}`
    );
  },

  getCompanyData: () => {
    return Axios.get(`/api/Companies/GetCompanyData`);
  },

  fetchCompanyByID: (id) => {
    return Axios.get(`/api/Companies/GetCompanyById/${id}`);
  },

  fetchCompanySizeCount: () => {
    return Axios.get("/api/Companies/GetCompanySizeCount");
  },

  updateCompany: (data, companyId) => {
    return Axios({
      url: `/api/Companies/UpdateCompany/${companyId}`,
      method: "PUT",
      data: data,
    });
  },

  updateSociallyResponsible: (companyId) => {
    return Axios({
      url: `/api/Companies/UpdateSociallyResponsible/${companyId}`,
      method: "PUT",
    });
  },

  updatePartnerStatus: (companyId, partnerCode) => {
    return Axios({
      url: `/api/Companies/UpdatePartnerStatus/${companyId}/${partnerCode}`,
      method: "PUT",
    });
  },

  deleteCompany: (id) => {
    return Axios.delete(`/api/Companies/DeleteCompany/${id}`);
  },
};
