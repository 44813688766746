import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { Navigate } from "react-router-dom";
import { AuthService } from "auth/AuthService";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

//user-administration
const UserAdministrationComponent = Loadable(
  lazy(() => import("pages/user-administration/UserAdministrationComponent"))
);
const UserAdministrationCreateComponent = Loadable(
  lazy(() =>
    import("pages/user-administration/UserAdministrationCreateComponent")
  )
);
const UserAdministrationEditComponent = Loadable(
  lazy(() =>
    import("pages/user-administration/UserAdministrationEditComponent")
  )
);
const UserAdministrationDeleteComponent = Loadable(
  lazy(() =>
    import("pages/user-administration/UserAdministrationDeleteComponent")
  )
);
const UserAdministrationBlockUserComponent = Loadable(
  lazy(() =>
    import("pages/user-administration/UserAdministrationBlockUserComponent")
  )
);
const UserAdministrationSociallyResponsibleComponent = Loadable(
  lazy(() =>
    import(
      "pages/user-administration/UserAdministrationSociallyResponsibleComponent"
    )
  )
);
const UserAdministrationPartnersComponent = Loadable(
  lazy(() =>
    import("pages/user-administration/UserAdministrationPartnersComponent")
  )
);

//data-administration
const DataAdministrationComponent = Loadable(
  lazy(() => import("pages/data-administration/DataAdministrationComponent"))
);

//profile
const ProfileComponent = Loadable(
  lazy(() => import("pages/profile/ProfileComponent"))
);
const ProfileDataEditComponent = Loadable(
  lazy(() => import("pages/profile/ProfileDataEditComponent"))
);
const ProfileSubsrciptionEditComponent = Loadable(
  lazy(() => import("pages/profile/ProfileSubsrciptionEditComponent"))
);
const EnterNewPasswordModal = Loadable(
  lazy(() => import("pages/profile/EnterNewPasswordModal"))
);

const SubscriptionExpiredComponent = Loadable(
  lazy(() => import("pages/profile/SubscriptionExpiredComponent"))
);

//sectors
const SectorsComponent = Loadable(
  lazy(() => import("pages/sectors/SectorsComponent"))
);
const SectorsCreateComponent = Loadable(
  lazy(() => import("pages/sectors/SectorsCreateComponent"))
);
const SectorsEditComponent = Loadable(
  lazy(() => import("pages/sectors/SectorsEditComponent"))
);
const SectorsDeleteComponent = Loadable(
  lazy(() => import("pages/sectors/SectorsDeleteComponent"))
);

//telopAdmin
const TelopComponent = Loadable(
  lazy(() => import("pages/telop/TelopComponent"))
);
const TelopCreateComponent = Loadable(
  lazy(() => import("pages/telop/TelopCreateComponent"))
);
const TelopEditComponent = Loadable(
  lazy(() => import("pages/telop/TelopEditComponent"))
);
const TelopDeleteComponent = Loadable(
  lazy(() => import("pages/telop/TelopDeleteComponent"))
);

//publications
const PublicationComponent = Loadable(
  lazy(() => import("pages/publications/PublicationComponent"))
);
const PublicationCreateComponent = Loadable(
  lazy(() => import("pages/publications/PublicationCreateComponent"))
);
const PublicationEditComponent = Loadable(
  lazy(() => import("pages/publications/PublicationEditComponent"))
);
const PublicationDeleteComponent = Loadable(
  lazy(() => import("pages/publications/PublicationDeleteComponent"))
);

//subscription price list
const SubscriptionPriceListComponent = Loadable(
  lazy(() =>
    import("pages/subscription-pricelist/SubscriptionPriceListComponent")
  )
);
const SubscriptionPriceListEditComponent = Loadable(
  lazy(() =>
    import("pages/subscription-pricelist/SubscriptionPriceListEditComponent")
  )
);

//activity types
const ActivityTypesComponent = Loadable(
  lazy(() => import("pages/activity-types/ActivityTypesComponent"))
);
const ActivityTypesCreateComponent = Loadable(
  lazy(() => import("pages/activity-types/ActivityTypesCreateComponent"))
);
const ActivityTypeEditComponent = Loadable(
  lazy(() => import("pages/activity-types/ActivityTypeEditComponent"))
);
const ActivityTypeDeleteComponent = Loadable(
  lazy(() => import("pages/activity-types/ActivityTypeDeleteComponent"))
);

//subscription packages
const SubscriptionPackageComponent = Loadable(
  lazy(() => import("pages/subscription-packages/SubscriptionPackageComponent"))
);
const SubscriptionPackagePreviewComponent = Loadable(
  lazy(() =>
    import("pages/subscription-packages/SubscriptionPackagePreviewComponent")
  )
);
const SubscriptionPackageEditComponent = Loadable(
  lazy(() =>
    import("pages/subscription-packages/SubscriptionPackageEditComponent")
  )
);

//banner types
const BannerTypesComponent = Loadable(
  lazy(() => import("pages/banner-types/BannerTypesComponent"))
);
const BannerTypesCreateComponent = Loadable(
  lazy(() => import("pages/banner-types/BannerTypesCreateComponent"))
);
const BannerTypesEditComponent = Loadable(
  lazy(() => import("pages/banner-types/BannerTypesEditComponent"))
);
const BannerTypesDeleteComponent = Loadable(
  lazy(() => import("pages/banner-types/BannerTypesDeleteComponent"))
);

//banner images
const BannerImagesComponent = Loadable(
  lazy(() => import("pages/banner-images/BannerImagesComponent"))
);
const BannerImagesCreateComponent = Loadable(
  lazy(() => import("pages/banner-images/BannerImagesCreateComponent"))
);
const BannerImagesEditComponent = Loadable(
  lazy(() => import("pages/banner-images/BannerImagesEditComponent"))
);
const BannerImagesDeleteComponent = Loadable(
  lazy(() => import("pages/banner-images/BannerImagesDeleteComponent"))
);

//events
const EventsComponent = Loadable(
  lazy(() => import("pages/events/EventsComponent"))
);
const EventsCreateComponent = Loadable(
  lazy(() => import("pages/events/EventsCreateComponent"))
);
const EventsEditComponent = Loadable(
  lazy(() => import("pages/events/EventsEditComponent"))
);
const EventsDeleteComponent = Loadable(
  lazy(() => import("pages/events/EventsDeleteComponent"))
);

//reports
const AdminReportComponent = Loadable(
  lazy(() => import("pages/admin-report/AdminReportComponent"))
);
const UserReportComponent = Loadable(
  lazy(() => import("pages/user-report/UserReportComponent"))
);

//calculation
const CalculationComponent = Loadable(
  lazy(() => import("pages/calculation/CalculationComponent"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/app",
  element: <MainLayout />,
  children: [
    {
      path: "/app",
      element: (
        <RequireAuth redirectTo="/app/login">
          <ProfileComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/enter-new-password",
          element: (
            <RequireAuth redirectTo="/app/login">
              <EnterNewPasswordModal />
            </RequireAuth>
          ),
        },
        {
          path: "/app/subscription-expired",
          element: (
            <RequireAuth redirectTo="/app/login">
              <SubscriptionExpiredComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/user-administration",
      element: (
        <RequireAuth redirectTo="/app/login">
          <UserAdministrationComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/user-administration/create-user",
          element: (
            <RequireAuth redirectTo="/app/login">
              <UserAdministrationCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/user-administration/:id/edit",
          element: (
            <RequireAuth redirectTo="/app/login">
              <UserAdministrationEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/user-administration/:userAccountId/delete",
          element: (
            <RequireAuth redirectTo="/app/login">
              <UserAdministrationDeleteComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/user-administration/:userAccountId/block/:isValid",
          element: (
            <RequireAuth redirectTo="/app/login">
              <UserAdministrationBlockUserComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/user-administration/sociallyResponsible/:companyId",
          element: (
            <RequireAuth redirectTo="/app/login">
              <UserAdministrationSociallyResponsibleComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/user-administration/partners/:companyId",
          element: (
            <RequireAuth redirectTo="/app/login">
              <UserAdministrationPartnersComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/data-administration",
      element: (
        <RequireAuth redirectTo="/app/login">
          <DataAdministrationComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/profile",
      element: (
        <RequireAuth redirectTo="/app/login">
          <ProfileComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/profile/edit-profile",
          element: (
            <RequireAuth redirectTo="/app/login">
              <ProfileDataEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/profile/edit-subscription",
          element: (
            <RequireAuth redirectTo="/app/login">
              <ProfileSubsrciptionEditComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/sectors",
      element: (
        <RequireAuth redirectTo="/app/login">
          <SectorsComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/sectors/create-sector",
          element: (
            <RequireAuth redirectTo="/app/login">
              <SectorsCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/sectors/edit/:activitySectorId/",
          element: (
            <RequireAuth redirectTo="/app/login">
              <SectorsEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/sectors/delete/:activitySectorId",
          element: (
            <RequireAuth redirectTo="/app/login">
              <SectorsDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/telop",
      element: (
        <RequireAuth redirectTo="/app/login">
          <TelopComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/telop/create-telop",
          element: (
            <RequireAuth redirectTo="/app/login">
              <TelopCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/telop/edit/:telopId",
          element: (
            <RequireAuth redirectTo="/app/login">
              <TelopEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/telop/delete/:telopId",
          element: (
            <RequireAuth redirectTo="/app/login">
              <TelopDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/publications",
      element: (
        <RequireAuth redirectTo="/app/login">
          <PublicationComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/publications/create-publication",
          element: (
            <RequireAuth redirectTo="/app/login">
              <PublicationCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/publications/edit/:publicationId/",
          element: (
            <RequireAuth redirectTo="/app/login">
              <PublicationEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/publications/delete/:publicationId",
          element: (
            <RequireAuth redirectTo="/app/login">
              <PublicationDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/subscription-pricelist",
      element: (
        <RequireAuth redirectTo="/app/login">
          <SubscriptionPriceListComponent />
        </RequireAuth>
      ),
      children: [
        // {
        //   path: "/app/subscription-pricelist/create-sector",
        //   element: (
        //     <RequireAuth redirectTo="/app/login">
        //       <SectorsCreateComponent />
        //     </RequireAuth>
        //   ),
        // },
        {
          path: "/app/subscription-pricelist/edit/:subscriptionPriceListId/",
          element: (
            <RequireAuth redirectTo="/app/login">
              <SubscriptionPriceListEditComponent />
            </RequireAuth>
          ),
        },
        // {
        //   path: "/app/subscription-pricelist/delete/:subscriptionPriceListId",
        //   element: (
        //     <RequireAuth redirectTo="/app/login">
        //       <SectorsDeleteComponent />
        //     </RequireAuth>
        //   ),
        // },
      ],
    },
    {
      path: "/app/banner-types",
      element: (
        <RequireAuth redirectTo="/app/login">
          <BannerTypesComponent />
        </RequireAuth>
      ),
      // children: [
      //   {
      //     path: "/app/banner-types/create-event",
      //     element: (
      //       <RequireAuth redirectTo="/app/login">
      //         <BannerTypesCreateComponent />
      //       </RequireAuth>
      //     ),
      //   },
      //   {
      //     path: "/app/banner-types/:bannerTypeId/edit",
      //     element: (
      //       <RequireAuth redirectTo="/app/login">
      //         <BannerTypesEditComponent />
      //       </RequireAuth>
      //     ),
      //   },
      //   {
      //     path: "/app/banner-types/:bannerTypeId/delete",
      //     element: (
      //       <RequireAuth redirectTo="/app/login">
      //         <BannerTypesDeleteComponent />
      //       </RequireAuth>
      //     ),
      //   },
      // ],
    },
    {
      path: "/app/banner-images",
      element: (
        <RequireAuth redirectTo="/app/login">
          <BannerImagesComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/banner-images/create",
          element: (
            <RequireAuth redirectTo="/app/login">
              <BannerImagesCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/banner-images/:bannerImageId/edit",
          element: (
            <RequireAuth redirectTo="/app/login">
              <BannerImagesEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/banner-images/:bannerImageId/delete",
          element: (
            <RequireAuth redirectTo="/app/login">
              <BannerImagesDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/events",
      element: (
        <RequireAuth redirectTo="/app/login">
          <EventsComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/events/create-event",
          element: (
            <RequireAuth redirectTo="/app/login">
              <EventsCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/events/:eventId/edit",
          element: (
            <RequireAuth redirectTo="/app/login">
              <EventsEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/events/:eventId/delete",
          element: (
            <RequireAuth redirectTo="/app/login">
              <EventsDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/activity-types",
      element: (
        <RequireAuth redirectTo="/app/login">
          <ActivityTypesComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/activity-types/create-activity-type",
          element: (
            <RequireAuth redirectTo="/app/login">
              <ActivityTypesCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/activity-types/:activityTypeId/edit",
          element: (
            <RequireAuth redirectTo="/app/login">
              <ActivityTypeEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/activity-types/:activityTypeId/delete",
          element: (
            <RequireAuth redirectTo="/app/login">
              <ActivityTypeDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/subscription-packages",
      element: (
        <RequireAuth redirectTo="/app/login">
          <SubscriptionPackageComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/subscription-packages/:subscriptionPackageId/preview",
          element: (
            <RequireAuth redirectTo="/app/login">
              <SubscriptionPackagePreviewComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/subscription-packages/:subscriptionPackageId/edit",
          element: (
            <RequireAuth redirectTo="/app/login">
              <SubscriptionPackageEditComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/admin-report",
      element: (
        <RequireAuth redirectTo="/app/login">
          <AdminReportComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/user-report",
      element: (
        <RequireAuth redirectTo="/app/login">
          <UserReportComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/calculation",
      element: (
        <RequireAuth redirectTo="/app/login">
          <CalculationComponent />
        </RequireAuth>
      ),
    },
  ],
};

function RequireAuth({ children, redirectTo }) {
  const data = AuthService.getDecodedUserJWT();
  var isAuth = window.localStorage.getItem("isAuth");

  // const { isAuthenticated, user, loading } = useSelector((state) => state.auth)

  // const userHasRequiredRole = user && roles.includes(user.role) ? true : false
  // if (!data?.accessToken != "") {
  //   return <Navigate to={redirectTo} replace />;
  // }

  if (!isAuth) {
    return <Navigate to={redirectTo} replace />;
  }

  // if (isAuthenticated && !userHasRequiredRole) {
  //   return <AccessDenied /> // build your won access denied page (sth like 404)
  // }

  return children;
}

export default MainRoutes;
