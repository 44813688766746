import jwt_decode from "jwt-decode";
var _ = require("lodash");

export const AuthService = {
  getToken: function () {
    var currentToken = window.localStorage.getItem("accessToken");
    if (currentToken) {
      return JSON.parse(currentToken);
    }
    return null;
  },
  setToken: function (tokenData) {
    if (tokenData) {
      window.localStorage.setItem(
        "accessToken",
        JSON.stringify(tokenData.accessToken)
      );
      window.localStorage.setItem("isAuth", true);
      window.localStorage.setItem(
        "subscriptionPriceListId",
        jwt_decode(JSON.parse(JSON.stringify(tokenData.accessToken)))
          .SubscriptionPriceListId
      );
      window.localStorage.setItem(
        "subscriptionTypeId",
        jwt_decode(JSON.parse(JSON.stringify(tokenData.accessToken)))
          .SubscriptionTypeId
      );
      window.localStorage.setItem("currentUser", JSON.stringify(tokenData));
    }
  },
  getDecodedUserJWT: function () {
    let currentUser = window.localStorage.getItem("accessToken");
    if (currentUser) {
      const parsedValue = JSON.parse(currentUser);
      const decodedToken = jwt_decode(parsedValue);
      return decodedToken;
    }
    return null;
  },
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    user.Token = token;
    localStorage.setItem("accessToken", JSON.stringify(token));
  },
  getCurrentUser: function () {
    var currentUser = window.localStorage.getItem("currentUser");
    if (currentUser) {
      return JSON.parse(currentUser);
    }
    return null;
  },
  hasAnyRole: (roles) => {
    var token = AuthService.getDecodedUserJWT();
    var tokenRole = [];

    if (token !== null) {
      Object.entries(token).map(function (item) {
        if (_.includes(item[0], "role")) {
          tokenRole.push(item[1]);
        }
      });
      if (_.intersectionWith(tokenRole, roles).length > 0) {
        return true;
      }
    } else {
      return false;
    }
  },
};
