import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "./components/ScrollTop";
import { useEffect } from "react";

//auth
import { AuthRepository } from "auth/AuthRepository";
import { AuthService } from "auth/AuthService";
import jwt_decode from "jwt-decode";

//cookies
import CookiesComponent from "components/CookiesComponent/CookiesComponent";
//sass
import "../src/styles/app.scss";

export default function App() {
  useEffect(() => {
    let token = AuthService.getToken();
    if (token) {
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();
      //JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        AuthRepository.logout();
      }
    }
  }, []);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
        <CookiesComponent />
      </ScrollTop>
    </ThemeCustomization>
  );
}
