import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import gbFlag from "../assets/img/gb_flag.png";
import mkFlag from "../assets/img/mk_flag.png";
import rsFlag from "../assets/img/rs_flag.png";
import bgFlag from "../assets/img/bg_flag.png";
import alFlag from "../assets/img/al_flag.png";
import { changeLanguage } from "properties/Locale";

export default function ChangeLanguageComponent({ hoverColor }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const languageArr = [
    {
      id: 1,
      name: "English",
      icon: gbFlag,
      language: "en",
    },
    {
      id: 2,
      name: "Macedonian",
      icon: mkFlag,
      language: "mk",
    },
    {
      id: 3,
      name: "Serbian",
      icon: rsFlag,
      language: "rs",
    },
    {
      id: 4,
      name: "Bulgarian",
      icon: bgFlag,
      language: "bg",
    },
    {
      id: 5,
      name: "Albanian",
      icon: alFlag,
      language: "al",
    },
  ];

  return (
    <div>
      <Button
        sx={{
          border: "none",
          color: "white",
          "&:hover": {
            backgroundColor: "transparent",
            color: hoverColor,
          },
        }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <LanguageIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ marginTop: 0.8 }}
      >
        <Box sx={{ paddingX: 0.5 }}>
          {languageArr.map((x) => {
            return (
              <MenuItem
                key={x.id}
                onClick={() => {
                  handleClose();
                  changeLanguage(x.language);
                }}
              >
                <Box
                  component="img"
                  alt={x.name}
                  sx={{ width: 25, height: 15 }}
                  src={x.icon}
                />
              </MenuItem>
            );
          })}
        </Box>
      </Menu>
    </div>
  );
}
