// material-ui
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

// loader style
const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: "#e05745",
  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#f0270dd1",
  },
}));

// ==============================|| Loader ||============================== //
const Loader = () => (
  <LoaderWrapper>
    <LinearProgress />
  </LoaderWrapper>
);

export default Loader;
