import { lazy } from "react";

// project import
import Loadable from "components/Loadable";

// render - 404
const PageNotFound = Loadable(lazy(() => import("components/PageNotFound/index")));

// ==============================|| AUTH ROUTING ||============================== //

const NotFoundRoutes = {
  path: "*",
  element: <PageNotFound />,
};

export default NotFoundRoutes;
