import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  // Tab,
  // Tabs,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import ProfileTab from "./ProfileTab";
// import SettingTab from "./SettingTab";
import { AuthService } from "auth/AuthService";
import { AuthRepository } from "auth/AuthRepository";

// assets
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import { LOCALE } from "properties/Locale";
import ChangeLanguageComponent from "components/ChangeLanguageComponent";
import { CompanyRepository } from "repositories/CompanyRepository";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `profile-tab-${index}`,
//     "aria-controls": `profile-tabpanel-${index}`,
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const [companyProfileData, setCompanyProfileData] = useState();
  const theme = useTheme();
  const data = AuthService.getCurrentUser()?.data;

  useEffect(() => {
    loadCompanyData();
  }, []);

  const loadCompanyData = () => {
    CompanyRepository.getCompanyData()
      .then((res) => {
        setCompanyProfileData(res.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log(err);
        }
      });
  };

  const handleLogout = async () => {
    AuthRepository.logout().then(
      (res) => {},
      (err) => {}
    );
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen = "grey.300";

  return (
    <Box
      sx={{ flexShrink: 0, ml: 0.75, display: "flex", alignItems: "center" }}
    >
      <Box>
        <ChangeLanguageComponent />
      </Box>
      <Box>
        <ButtonBase
          sx={{
            p: 0.5,
            bgcolor: open ? iconBackColorOpen : "transparent",
            borderRadius: 1,
            "&:hover": { bgcolor: "secondary.lighter" },
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Avatar alt="profile user" sx={{ width: 32, height: 32 }} />
        </ButtonBase>
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 9],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              {open && (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: 290,
                    minWidth: 240,
                    maxWidth: 290,
                    [theme.breakpoints.down("md")]: {
                      maxWidth: 250,
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <CardContent>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Stack
                              direction="row"
                              spacing={1.25}
                              alignItems="center"
                            >
                              <Avatar
                                alt="profile user"
                                sx={{ width: 32, height: 32 }}
                              />
                              <Typography variant="body2" color="textSecondary">
                                {companyProfileData?.name}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </CardContent>
                      {open && (
                        <>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              zIndex: 9999999999999,
                            }}
                          ></Box>
                          <TabPanel
                            value={value}
                            index={0}
                            dir={theme.direction}
                          >
                            <ProfileTab handleLogout={handleLogout} />
                          </TabPanel>
                        </>
                      )}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

export default Profile;
