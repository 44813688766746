import Axios from 'axios'
import { SETTINGS } from '../properties/ApiProps'

export const AuthRepository = {
  fetchToken: (user) => {
    return Axios({
      url: '/api/Authentication/AuthenticateUserAccount',
      method: 'POST',
      data: user,
    })
  },

  refreshToken: () => {
    return Axios({
      url: 'oauth/token',
      method: 'POST',
      params: {
        refresh_token: JSON.parse(localStorage.getItem('accessToken'))
          .refresh_token,
        grant_type: 'refresh_token',
      },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    })
  },

  logout: () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('currentUser')
    localStorage.removeItem('isAuth')
    window.location.href = '/app/login'
  },
}
